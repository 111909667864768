<template>
<div class="body-of-login">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-9">
        <div class="card">

          <div class="card-body">
            <div class="card-logo text-center">
              <img alt="Logo Kletterwald" src="../assets/logo.png" width="150px">
            </div>
            <form action="#" @submit.prevent="submit">
              <div class="form-group row">
                <div class="col-md-12">
                  <input
                    id="username"
                    type="text"
                    class="form-control"
                    name="username"
                    placeholder="Benutzername"
                    value
                    required
                    autofocus
                    inputmode="email"
                    v-model="form.username"
                  />
                </div>
              </div>

              <div class="form-group row">

                <div class="col-md-12">
                  <input
                    id="password"
                    type="password"
                    class="form-control"
                    name="password"
                    placeholder="Passwort"
                    required
                    v-model="form.password"
                  />
                </div>
              </div>

              <div class="form-group row mb-0">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-primary btn-block">Anmelden</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div v-if="error" class="alert alert-danger text-center mt-3"><i class="bi bi-exclamation-triangle-fill"></i> {{error}}</div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.body-of-login {
    min-height: 100vh;
    padding: 0;
    margin: 0;
    background-image: url("../assets/bg-login.jpeg");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #464646;
    position: fixed;
    top: 0;
    bottom:0;
    left:0;
    width: 100%;
    height: 100%;
}
.card {
  border-radius: 0;
  height: 400px;
  background-color: rgb(223, 223, 221);
  opacity: 1;
}
.card-logo {
  padding-bottom: 2.25em;
}
.btn-primary {
  margin-top: 30px;
}
</style>

<script>
import { firebaseApp } from '../firebaseDb'

export default {
  data () {
    return {
      form: {
        username: "",
        password: ""
      },
      error: null
    }
  },
  methods: {
    submit () {
      let user = ""
      if (this.form.username === "darmstadt" || this.form.username === "Darmstadt") {
        user = "darmstadt@einweisungsapp.de"
      } else if (this.form.username === "neroberg" || this.form.username === "Neroberg") {
        user = "neroberg@einweisungsapp.de"
      } else {
        user = this.form.username
      }
      firebaseApp
        .auth()
        .signInWithEmailAndPassword(user, this.form.password)
        .then(data => {
          this.$router.push({ name: "dashboard" })
        })
        .catch(err => {
          console.log("Auth error: " + err.code)
          this.form.password = ""
          if (err.code === "auth/user-not-found") {
            this.error = "Benutzer wurde nicht gefunden."
          } else if (err.code === "auth/wrong-password") {
            this.error = "Passwort ungültig."
          } else if (err.code === "auth/invalid-email") {
            this.error = "Der Benutzername ist ungültig."
          } else if (err.code === "auth/too-many-requests") {
            this.error = "Der Benutzer wurde aufgrund zu vieler fehlgeschlagener Anmeldeversuche temporär gesperrt."
          } else if (err.code === "auth/user-disabled") {
            this.error = "Der Benutzer ist deaktiviert."
          } else {
            this.error = "Anmeldung fehlgeschlagen (" + err.message + ")"
          }
        })
    }
  }
}
</script>
